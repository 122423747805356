import { numberWithCommas } from "../../../common";
import useAdmission from "../../../hooks/useAdmission";
import useProspective from "../../../hooks/useProspective";
import StudentInfo from "../../application/components/StudentInfo";
import "./Style.css";

const ApprovedPaymentComponent = ({
  loading,
  btnText,
  handlePayment,
  acceptanceFee,
  stateOfOrigin,
}) => {
  const { data } = useAdmission();
  const { data: datas } = useProspective();
  const prospective_state = stateOfOrigin?.toLowerCase() === "delta";
  return (
    <>
      {data && (
        <div>
          <div class="card p-5" style={{ padding: "10px" }}>
            <div>
              <p class="business">
                {acceptanceFee?.name && "Acceptance Fee payment"}
              </p>
            </div>
            <StudentInfo data={datas} />
            <div class="pricing p-3 rounded mt-4 mb-4 d-flex justify-content-between">
              <div class="d-flex flex-row align-items-center">
                <sup class="dollar font-weight">₦</sup>
                <span class="amount ml-1 mr-1">
                  {prospective_state ? "70,000" : numberWithCommas(acceptanceFee?.amount)}
                </span>
              </div>
            </div>
            <div class="mt-3">
              <button
                class="btn btn-primary btn-block btn-raised waves-effect payment-button"
                onClick={handlePayment}
                disabled={loading}
              >
                {btnText} <i class="fa fa-long-arrow-right"></i>
              </button>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default ApprovedPaymentComponent;
