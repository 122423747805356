import React, { useEffect, useState } from "react";
import QRCode from "react-qr-code";
import { useLocation, useNavigate } from "react-router-dom";
import logo from "../../assets/images/ozoro.png";
import { getCurrentUser } from "../../components/services/auth/authService";
import moment from "moment";
import useDetails from "../../hooks/useDetails";
import avatar from "../../assets/images/user.png"
import useProspective from "../../hooks/useProspective";

function ApprovedInvoice() {
    const [student, setStudent] = useState();
    const [passport, setPassport] = useState();
    const [reference, setReference] = useState()
    const [amount, setAmount] = useState()
    const [createdAt, setCreatedAt] = useState()
    const [paymentName, setPaymentName] = useState()
    const [general, setGeneral] = useState()
    const navigate = useNavigate();

  const { data, prospectiveData } = useProspective()
  
    const { state } = useLocation();
    const user = getCurrentUser()
  
    const printPaymentReceipt = () => {
      window.print();
    };
    const onImageError = (e) => {
      e.target.src = avatar
    }
  
  
    useEffect(() => {
    //   getPassport();
      console.log(state);
      setGeneral(state.invoice)
      setStudent(state?.invoice?.application);
      setReference(state?.invoice?.paystack_ref)
      setAmount(state?.invoice?.amount_paid)
      setCreatedAt(state?.invoice?.generated_date)
      // setPassport(state?.invoice?.application?.photo)
    }, []);


  return (
    <>
      <div className="print-button">
        <button
          onClick={() => navigate(-1)}
          className="btn-danger"
        >
          Go back
        </button>
        <button className="btn-primary" onClick={printPaymentReceipt}>Print</button>
      </div>
      {general && (
        <div className="receipt">
          <div className="contents">
            <div className="receipt-header">
              <div className="school-title">
                <img src={logo} alt="DSP" className="logo" />
                <h3 className="name">Delta State University of Science and Technology, Ozoro</h3>
              </div>
              <h4 className="subheader">
                Payment Slip <span>{reference}</span>
              </h4>
            </div>
            <div className="receipt-body">
              <h4 style={{ background: "#0c0f2e" }} className="subtitle">
                Base Data
              </h4>
              <div className="body-header">
                <div className="passport">
                  <img src={'https://ozoro-coewarri.pythonanywhere.com'+data?.photo} alt="Passport" onError={onImageError}/>
                </div>
                <div className="user-details">
                  <div>
                    <h3 className="user-details-option">Name:</h3>
                    <h3 className="user-details-info">
                      {data?.surname} {data?.othername}{" "}
                    </h3>
                  </div>
                  <div>
                    <h3 className="user-details-option">Application Number:</h3>
                    <h3 className="user-details-info">
                      {data?.username}
                    </h3>
                  </div>
                  <div>
                    <h3 className="user-details-option">Email:</h3>
                    <h3 className="user-details-info">
                      {data?.email}
                    </h3>
                  </div>
                  <div>
                    <h3 className="user-details-option">Approved Program:</h3>
                    <h3 className="user-details-info">
                      {prospectiveData?.programme}
                    </h3>
                  </div>
                  <div>
                    <h3 className="user-details-option">Entry Mode:</h3>
                    <h3 className="user-details-info">
                      {data?.entryMode}
                    </h3>
                  </div>
                  <div>
                    <h3 className="user-details-option">Study Mode:</h3>
                    <h3 className="user-details-info">
                      {data?.studyMode}
                    </h3>
                  </div>
                  <div>
                    <h3 className="user-details-option">Session:</h3>
                    <h3 className="user-details-info">
                      {data?.entrySession}/2025
                    </h3>
                  </div>
                </div>
                <div className="qr-code">
                  {data && <QRCode value={reference} size={90} />}
                </div>
              </div>
              <h4 style={{ background: "#0c0f2e" }} className="subtitle">
                Payment Data
              </h4>

              <div className="body-info">
                <div>
                  <h3 className="user-details-option">Amount Paid:</h3>
                  <h3 className="user-details-info">₦{Number(amount)?.toLocaleString()}</h3>
                </div>
                <div>
                  <h3 className="user-details-option">Date of Payment:</h3>
                  <h3 className="user-details-info">{moment(createdAt).format("DD/MM/YYYY hh:mm")}</h3>
                </div>
                <div>
                  <h3 className="user-details-option">Payment Description:</h3>
                  <h3 className="user-details-info"> Payment for 2024/2025 Acceptance Fee</h3>
                </div>
                <div>
                  <h3 className="user-details-option">Payment Reference:</h3>
                  <h3 className="user-details-info">{reference}</h3>
                </div>
                <div>
                  <h3 className="user-details-option">Payment Gateway:</h3>
                  <h3 className="user-details-info">PayStack</h3>
                </div>
              </div>
            </div>
            <div className="receipt-footer">
              <p>Date: {moment(student?.update).format("DD/MM/YYYY hh:mm")}</p>
              <p>
                {reference} - Online Payment Slip p6389714479930 -
                Page 1 of 1
              </p>
            </div>
          </div>
        </div>
      )}
      {!general && (
        <center>
          <div
            class="preloader pl-size-xs"
            style={{ marginRight: "5px", marginTop: "100px" }}
          >
            <div class="spinner-layer pl-red-grey">
              <div class="circle-clipper left">
                <div class="circle"></div>
              </div>
              <div class="circle-clipper right">
                <div class="circle"></div>
              </div>
            </div>
          </div>
        </center>
      )}
    </>
  )
}

export default ApprovedInvoice