import { DragAndDropService } from "ag-grid-community";
import React, { UseEffect, useEffect, useState } from "react";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import { Link, NavLink, useLocation, useNavigate } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import api from "../../api/api";
import Footer from "../../components/Footer";
import Logo from "../../components/Logo";
import { removeUser, setCurrentUser } from "../../components/services/auth/authService";
import { asyncLocalStorage } from "../../utils/appParams";
import NavHeader from "./components/NavHeader";

const ApplicationLogin = () => {
  const {state} = useLocation()
  const [username, setUsername] = useState(state ? state?.state?.id : "");
  const [password, setPassword] = useState("");
  const [loading, setLoading] = useState(false);
  const [alertClose, setAlertClose] = useState(true)
  const [btnText, setBtnText] = useState('Login') 

  const navi = useNavigate();

  const errorProp = {
    position: "top-right",
    autoClose: 2000,
    hideProgressBar: true,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    theme: "colored",
  };

  const warnProp = {
    position: "top-right",
    autoClose: 2000,
    hideProgressBar: true,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    theme: "colored",
  };

  const successProp = {
    position: "top-right",
    autoClose: 2000,
    hideProgressBar: true,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    theme: "colored",
  };

  const loginApplicant = async (e) => {
    e.preventDefault();
    try {
      setLoading(true);
      setBtnText('Loading...')
      const { data } = await api.post("/admissions/applicant/login", {
        username,
        password,
      });
      console.log("Result", data.data);
      setLoading(false);
      await asyncLocalStorage.setItem("user-token", data.data.access);
      setCurrentUser(data.data);
      toast.success("Login successful", successProp);
      setBtnText('Login')
      if (data?.data?.admission_id !== undefined) {
        navi('/admission')
      } else {
        toast.info("Sorry, you have not been offered admission yet");
        removeUser()
        
      }
    } catch (error) {
      console.log(error);
      setBtnText('Failed, try again!')
      setLoading(false);
      toast.error(error.response.data.error.message, errorProp);
    }
  };

  useEffect(() => {console.log(state)}, [])

  return (
    <>
    <NavHeader/>
    <div class="account-body">
      <div class="container">
        <div class="row vh-100 ">
          <div class="col-12 align-self-center">
            <div class="auth-page">
              <div class="card auth-card shadow-lg">
                <div class="card-body">
                  <div class="px-3">
                    <div class="text-center auth-logo-text">
                      <h4 class="mt-0 mb-3 mt-5">Undergraduate Acceptance Portal (2024/2025)</h4>
                      <p class="text-muted mb-0">
                        Sign in to continue your acceptance.
                      </p>
                    </div>

                    <form
                      class="form-horizontal auth-form my-4"
                      onSubmit={loginApplicant}
                    >
                      <div class="form-group">
                        {alertClose && 
                        <div className="alert dspg-success alert-dismissible fade show">
                          <p>Use your application/Jamb reg. number as <b>username</b> and your surname as <b>password</b></p>
                          <button type="button" class="close" data-dismiss="alert" aria-label="Close" onClick={() => setAlertClose(false)}>
                          <span aria-hidden="true">&times;</span>
                        </button>
                      </div>
                        }
                        <label for="username">Username</label>
                        <div class="input-group mb-3">
                          <span class="auth-form-icon">
                            <i class="dripicons-user"></i>
                          </span>
                          <input
                            type="text"
                            class="form-control"
                            id="username"
                            placeholder="Enter application number"
                            value={username}
                            onChange={(e) => setUsername(e.target.value)}
                            required
                          />
                        </div>
                      </div>

                      <div class="form-group">
                        <label for="userpassword">Password</label>
                        <div class="input-group mb-3">
                          <span class="auth-form-icon">
                            <i class="dripicons-lock"></i>
                          </span>
                          <input
                            type="password"
                            class="form-control"
                            id="userpassword"
                            placeholder="Enter password"
                            value={password}
                            onChange={(e) => setPassword(e.target.value)}
                            required
                          />
                        </div>
                      </div>

                      <div class="form-group row mt-4">
                        <div class="col-sm-6">
                          <div class="custom-control custom-switch switch-success">
                            <input
                              type="checkbox"
                              class="custom-control-input"
                              id="customSwitchSuccess"
                            />
                            <label
                              class="custom-control-label text-muted"
                              for="customSwitchSuccess"
                            >
                              Remember me
                            </label>
                          </div>
                        </div>
                        <div class="col-sm-6 text-right">
                          <a
                            href="/reset-password"
                            class="text-muted font-13"
                          >
                            <i class="dripicons-lock"></i> Forgot password?
                          </a>
                        </div>
                      </div>

                      <div class="form-group mb-0 row">
                          <div class="col-12 mt-2">
                            <button
                              class="btn btn-gradient-primary btn-round btn-block waves-effect waves-light"
                              type="submit"
                              disabled={loading}
                            >
                              {btnText} <i class="fas fa-sign-in-alt ml-1"></i>
                            </button>
                          </div>
                      </div>
                      <ToastContainer />
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    
    </>



    // <div class="bg-card">
    //     <div class="container-fluid">
    //         <div class="row vh-100">
    //             <div class="col-lg-4 pr-0">
    //                 <div class="auth-page">
    //                     <div class="card mb-0 shadow-none h-100">
    //                         <div class="card-body">
    //                             <Logo/>
    //                             <div class="px-3">
    //                                 <h2 class="font-weight-semibold font-22 mb-2">Delta State Polytechnic, <span class="text-primary">Ogwashi Uku</span>.</h2>
    //                                 <p class="text-muted">Try our fully featured business texting platform.</p>

    //                                 <ul class="nav-border nav nav-pills" role="tablist">
    //                                     <li class="nav-item">
    //                                         <NavLink className="nav-link font-weight-semibold" data-toggle="tab" to="/apply/login" role="tab">Log In</NavLink>
    //                                     </li>
    //                                     <li class="nav-item">
    //                                         <NavLink className="nav-link font-weight-semibold" data-toggle="tab" to="/apply/register" role="tab">Register</NavLink>
    //                                     </li>
    //                                 </ul>
    //                                 <div class="tab-content">
    //                                     <div class="tab-pane active p-3" id="LogIn_Tab" role="tabpanel">
    //                                         <form
    //                   class="form-horizontal auth-form my-4"
    //                   onSubmit={loginApplicant}
    //                 >
    //                   <div class="form-group">
    //                     <label for="username">Username</label>
    //                     <div class="input-group mb-3">
    //                       <span class="auth-form-icon">
    //                         <i class="dripicons-user"></i>
    //                       </span>
    //                       <input
    //                         type="text"
    //                         class="form-control"
    //                         id="username"
    //                         placeholder="Enter username"
    //                         value={username}
    //                         onChange={(e) => setUsername(e.target.value)}
    //                         required
    //                       />
    //                     </div>
    //                   </div>

    //                   <div class="form-group">
    //                     <label for="userpassword">Password</label>
    //                     <div class="input-group mb-3">
    //                       <span class="auth-form-icon">
    //                         <i class="dripicons-lock"></i>
    //                       </span>
    //                       <input
    //                         type="password"
    //                         class="form-control"
    //                         id="userpassword"
    //                         placeholder="Enter password"
    //                         value={password}
    //                         onChange={(e) => setPassword(e.target.value)}
    //                         required
    //                       />
    //                     </div>
    //                   </div>

    //                   <div class="form-group row mt-4">
    //                     <div class="col-sm-6">
    //                       <div class="custom-control custom-switch switch-success">
    //                         <input
    //                           type="checkbox"
    //                           class="custom-control-input"
    //                           id="customSwitchSuccess"
    //                         />
    //                         <label
    //                           class="custom-control-label text-muted"
    //                           for="customSwitchSuccess"
    //                         >
    //                           Remember me
    //                         </label>
    //                       </div>
    //                     </div>
    //                     <div class="col-sm-6 text-right">
    //                       <a
    //                         href="auth-recover-pw.html"
    //                         class="text-muted font-13"
    //                       >
    //                         <i class="dripicons-lock"></i> Forgot password?
    //                       </a>
    //                     </div>
    //                   </div>

    //                   <div class="form-group mb-0 row">
    //                     {loading ? (
    //                       <div class="preloader pl-size-xs">
    //                         <div class="spinner-layer pl-red-grey">
    //                           <div class="circle-clipper left">
    //                             <div class="circle"></div>
    //                           </div>
    //                           <div class="circle-clipper right">
    //                             <div class="circle"></div>
    //                           </div>
    //                         </div>
    //                       </div>
    //                     ) : (
    //                       <div class="col-12 mt-2">
    //                         <button
    //                           class="btn btn-gradient-primary btn-round btn-block waves-effect waves-light"
    //                           type="submit"
    //                         >
    //                           Login <i class="fas fa-sign-in-alt ml-1"></i>
    //                         </button>
    //                       </div>
    //                     )}
    //                   </div>
    //                   <ToastContainer />
    //                                         </form>
    //                                         <div class="mx-3 mt-3 text-center bg-light p-3 text-primary">
    //                                             <h4 class="">Don't have an account ? </h4>
    //                                             <Link to="/apply/register" class="btn btn-primary waves-effect waves-light">Register</Link>                
    //                                         </div>
                                            
    //                                     </div>
    //                                 </div> 
    //                             </div>
                                
    //                             <div class="mt-3 text-center">
    //                                 &copy; 2019 - 2020 Metrica <span class="text-muted d-none d-sm-inline-block float-right">Crafted with <i class="mdi mdi-heart text-danger"></i> by Mannatthemes</span>
    //                             </div>
    //                         </div>
    //                     </div>
    //                 </div>
    //             </div>
    //             <div class="col-lg-8 p-0 h-100vh d-flex justify-content-center"
    //               style={{backgroundImage: `url(/assets/images/1bg.jpg)`,
    //               height: "100% !important",
    //               backgroundRepeat: "no-repeat",
    //               backgroundSize: "cover",
    //               opacity:'0.9'}}>
    //                 <div class="accountbg d-flex align-items-center"> 
    //                     <div class="account-title text-center text-white">
    //                         {/* <img src="../assets/images/logo-sm.png" alt="" class="thumb-sm"/> */}
    //                         <h4 class="mt-3 text-white">Welcome To</h4>
    //                         <h1 class="text-white border border-warning bg-warning">Delta State Polytechnic</h1>
    //                         <p class="font-18">Ogwashi Uku</p>
    //                         <div class="border w-25 mx-auto border-warning"></div>
    //                     </div>
    //                 </div>
    //             </div>
    //         </div>
    //     </div>
        
    // </div>
  );
};

export default ApplicationLogin;
